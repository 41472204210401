import { ModalAcceptCancel } from "@viuti/recursos";
import { createContext, ReactNode, useContext, useState } from "react";
import styles from "./DeleteModal.module.css";

// 🌟 Context initial interface and values
interface DeleteModalContextType {
  updateTexts: (title: string, description: string) => void;
  onDelete: (onDelete: () => void) => void;
  showModal: (showModal: boolean) => void;
}

const DeleteContextInitialValue: DeleteModalContextType = {
  updateTexts: (a, b) => (): void => {},
  onDelete: (value) => (): void => {},
  showModal: (bool) => (): void => {},
};

// 🌟 Create the Toaster Context
export const DeleteContext = createContext<DeleteModalContextType>(
  DeleteContextInitialValue
);

// 🌟 Context provider to wrap around components needing the toaster functionality
export const DeleteModalProvider = ({ children }: { children: ReactNode }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState({
    modalText: "Eliminación texto",
    modalTitle: "Eliminación titulo",
  });
  const [onDelete, setOnDelete] = useState({ callback: () => {} });

  return (
    // 🛠️ Provide the context to the children
    <DeleteContext.Provider
      value={{
        updateTexts: (title, description) => {
          setModalProps({
            ...modalProps,
            modalText: description,
            modalTitle: title,
          });
        },
        onDelete: (d) => {
          setOnDelete({ callback: d });
        },
        showModal: (showModal) => setShowModal(showModal),
      }}
    >
      {children}
      {showModal && (
        <ModalAcceptCancel
          visibility={{
            isVisible: showModal,
            setIsVisible: () => setShowModal(false),
          }}
          children={
            <div className={styles.modal__container}>
              <h3 className={styles.modal__container__title}>
                {modalProps.modalTitle}
              </h3>
              <p className={styles.modal__container__p}>
                {modalProps.modalText}
              </p>
            </div>
          }
          buttonOne={{
            textButtonOne: "Cancelar",
            actionButtonOne: () => setShowModal(false),
          }}
          buttonTwo={{
            textButtonTwo: "Confirmar",
            actionButtonTwo: () => {
              onDelete.callback();
              setShowModal(false);
            },
          }}
          showButtons={{
            showButtonClose: true,
            showButtonOne: true,
            showButtonTwo: true,
          }}
          actionButtonClose={() => setShowModal(false)}
        />
      )}
    </DeleteContext.Provider>
  );
};

// ⚠️ Hook to use the toaster context with error handling
export const useDeleteModalContext = () => {
  const context = useContext(DeleteContext);
  if (!context) {
    throw new Error("DeleteContext must be used within a DeleteModalProvider");
  }
  return context;
};
