import React, { useContext } from "react";
import {
  HiArrowRight,
  HiTrash,
  HiOutlineDocumentDuplicate,
} from "react-icons/hi2";
import styles from "./SupplierListItems.module.css";
import useSupplierStore from "@/store/Suppliers.store";
import { ToasterContext } from "@Contexts/Toaster/Toaster.context";
import { IconWrapper } from "@viuti/recursos";
import {
  GENERIC_FAIL_TOASTER_MSG,
  GENERIC_SUCCESS_TOASTER_MSG,
} from "@Models/messages/generalMessages.messages";
import { Supplier } from "@Models/interfaces/Adapters.interfaces";
import { useNavigate } from "react-router-dom";
import { SUPPLIER_ROUTES } from "@Models/supplier.routes";
import { DeleteContext } from "@Contexts/DeleteModal/DeleteModal.context";
import { convertDaysToTerms } from "@/utils/unitDaysConversion.util";
import { useCopy } from "./hook/useCopy";
import useBankStore from "@/store/bank.store";

// Component for rendering a single supplier list item
const SupplierListItem: React.FC<Supplier> = (supplier) => {
  const { deleteSupplier } = useSupplierStore((state) => state);
  const toasterContext = useContext(ToasterContext);
  const deleteModal = useContext(DeleteContext);
  const banks = useBankStore();
  const navigate = useNavigate();
  const copyToClipboard = useCopy();

  // Function to handle deleting a supplier
  const onDeleteItem = async () => {
    try {
      const { isSuccess } = await deleteSupplier(supplier.id);
      if (isSuccess) {
        toasterContext.setToasterProps(GENERIC_SUCCESS_TOASTER_MSG);
      } else {
        toasterContext.setToasterProps(GENERIC_FAIL_TOASTER_MSG);
      }
    } catch (error) {
      toasterContext.setToasterProps(GENERIC_FAIL_TOASTER_MSG);
    }
  };

  const onViewItem = () =>
    navigate(SUPPLIER_ROUTES.EDIT_SUPPLIER + `/${supplier.id}`);

  return (
    <article className={styles.supplierTable__row}>
      <div className={styles.supplierTable__row__title}>
        <strong>{supplier.name}</strong>
        <p
          className={styles.__textOpacity__underline}
          onClick={() => {
            copyToClipboard(supplier.taxNumber);
          }}
        >
          {supplier.taxId === 1 && "Nro. RUC: "}
          {supplier.taxId === 2 && "Nro. Rut: "}
          {supplier.taxId === 3 && "Nro. Vat: "}
          {supplier.taxNumber} <HiOutlineDocumentDuplicate />
        </p>
      </div>
      <div className={styles.supplierTable__row__tag}>
        <p className={styles.__paragraph}>
          {/*@ts-ignore*/}
          {supplier.bankName === 1 && banks.banks[0].name}
          {/*@ts-ignore*/}
          {supplier.bankName === 2 && banks.banks[1].name}
          {/*@ts-ignore*/}
          {supplier.bankName === 3 && banks.banks[2].name}
          {/*@ts-ignore*/}
          {supplier.bankName === 4 && banks.banks[3].name}
          {/*@ts-ignore*/}
          {supplier.bankName === 5 && banks.banks[4].name}
          {/*@ts-ignore*/}
          {typeof supplier.bankName !== "number" && supplier.bankName}
        </p>
      </div>
      <div>
        <p>Nro. Cuenta</p>
        <p
          className={styles.__textOpacity__underline}
          onClick={() => {
            copyToClipboard(supplier.bankAccount);
          }}
        >
          {supplier.bankAccount === "0"
            ? "Sin especificar"
            : supplier.bankAccount}{" "}
          <HiOutlineDocumentDuplicate />
        </p>
      </div>
      <div>
        <p>Nro. interbanking</p>
        <p
          className={styles.__textOpacity__underline}
          onClick={() => {
            copyToClipboard(supplier.interbankAccount);
          }}
        >
          {supplier.interbankAccount} <HiOutlineDocumentDuplicate />
        </p>
      </div>
      <div>
        <p>Crédito</p>
        <p className={styles.__textOpacity}>
          {convertDaysToTerms(Number(supplier.creditDays))}
        </p>
      </div>
      <div className={styles.supplierTable__row__actions}>
        <button
          className={styles.supplierTable__row__buttonAction}
          onClick={onViewItem}
        >
          <HiArrowRight size={22} />
        </button>
        <button
          onClick={() => {
            deleteModal.onDelete(() => onDeleteItem());
            deleteModal.showModal(true);
            deleteModal.updateTexts(
              "Eliminar proveedor",
              "Estas a punto de eliminar a un proveedor, esta acción no se puedo deshacer. ¿Estas seguro?"
            );
          }}
          className={styles.supplierTable__row__buttonAction__delete}
        >
          <HiTrash size={22} color="#d7696d" />
        </button>
      </div>
    </article>
  );
};

export default SupplierListItem;
