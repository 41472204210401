import { NoInfoToShow, IconWrapper } from "@viuti/recursos";
import { FaPeopleCarry } from "react-icons/fa";
import { HiDocument, HiExclamationTriangle } from "react-icons/hi2";
import styles from "./EmptyState.module.css";

const EmptyState = ({
  title,
  message,
  icon = "SUPPLIER",
  isError = false,
}: {
  title: string;
  message: string;
  icon?: "SUPPLIER" | "BILL" | "ERROR";
  isError?: boolean;
}) => {
  const COLORS_DICTIONARY = {
    STANDARD: "#45348e",
    ERROR: "#d7696d",
  };

  const color = isError ? COLORS_DICTIONARY.ERROR : COLORS_DICTIONARY.STANDARD;
  const size = 48;

  const iconProps = {
    size,
    color,
  };

  return (
    <section className={styles.emptyState__container}>
      <NoInfoToShow title={title} message={message} error={isError}>
        {icon === "ERROR" && (
          <IconWrapper icon={<HiExclamationTriangle {...iconProps} />} />
        )}
        {icon === "SUPPLIER" && (
          <IconWrapper icon={<FaPeopleCarry {...iconProps} />} />
        )}
        {icon === "BILL" && (
          <IconWrapper icon={<HiDocument {...iconProps} />} />
        )}
      </NoInfoToShow>
    </section>
  );
};

export default EmptyState;
